import React from "react";
import { Trans } from "@lingui/macro";
import styled from "@emotion/styled";
import {
  Typography,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
} from "@mui/material";
import { LocalizedLink } from "gatsby-theme-i18n";
import NewLayout from "../components/NewLayout";
import Container from "../components/Container";
import ModalCookies from "../components/ModalCookies";

const values = [
  {
    a: "Google Analytics",
    b: "_ga",
    c: "Used to distinguish users",
    d: "Used to track visits to the website",
    e: "1 year",
  },
  {
    a: "Google Analytics",
    b: "_gid",
    c: "Visitor identification",
    d: "Used to track user journey",
    e: "1 year",
  },
  {
    a: "Allfunds",
    b: "cookies",
    c: "User cookie configuration",
    d: "Used to store the cookie configuration selected by the user",
    e: "1 year",
  },
  {
    a: "Allfunds",
    b: "googleTagManager",
    c: "Google Tag Manager configuration",
    d: "Used to activate/deactivate analytics",
    e: "1 year",
  },
];

const headers = () => [
  <Trans>Editor / Provider</Trans>,
  <Trans>Name of the cookie</Trans>,
  <Trans>Purpose of the cookie</Trans>,
  <Trans>Description</Trans>,
  <Trans>Retention period</Trans>,
];

function CookiesPage() {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <NewLayout
        byDefault
        apps={[
          { label: <Trans>Cookies Policy</Trans>, path: "/cookies" },
        ]}
      >
        <Container pb={{ xs: 4, sm: 8 }}>
          <Typography mt={3} mb={{ xs: 3, sm: 6 }} variant="h3">
            <Trans>Cookies Policy</Trans>
          </Typography>
          <Typography>
            <Trans>
              ALLFUNDS both its web page www.allfunds.com and private web uses
              Cookies, either web editor or partner companies, in order to
              improve and optimize the user’s experience.
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              This Cookies Policy applies to ALLFUNDS’s website, as well as to
              any other platform through which you may get our services.
            </Trans>
          </Typography>
          <H2>
            <Trans>What are cookies?</Trans>
          </H2>
          <Typography>
            <Trans>
              Cookies are small text files that are downloaded and stored in the
              user’s devices (computer/smartphone/tablet). They enable the
              website to remember browsing preferences and to improve the user’s
              navigation by making the interaction between the user and the
              website faster and simpler.
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              The type of cookies that can be used on this website are the
              following:
            </Trans>
          </Typography>
          <Typography>
            <Trans>Regarding the period of time they remain activated:</Trans>
          </Typography>
          <ul>
            <li>
              <Typography>
                <Trans>
                  <b>Session cookies</b>
                  : these cookies only last as long as
                  your web session, and disappear from your computer or device
                  when you close your browser.
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography>
                <Trans>
                  <b>Persistent cookies</b>
                  : these cookies could stay on your
                  computer or device after your browser has been closed and last
                  for a time specified in the cookie (in any case, no longer
                  that 24 months).
                </Trans>
              </Typography>
            </li>
          </ul>
          <Typography>
            <Trans>Regarding the editor that manages the cookie:</Trans>
          </Typography>
          <ul>
            <li>
              <Typography>
                <Trans>
                  First-party cookies: these cookies are set by the web editor
                  of the website that you are visiting.
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography>
                <Trans>
                  Third-party cookies: these cookies are set by third-parties
                  editors (i.e.: Google Analytics).
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography>
                <Trans>Regarding the purpose of the cookies:</Trans>
              </Typography>
            </li>
            <li>
              <Typography>
                <Trans>
                  Strictly necessary cookies: these cookies are necessary to
                  provide ALLFUNDS’ services in our website. Without them, the
                  services that you have asked for could not be provided.
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography>
                <Trans>
                  Personalization cookies: these cookies allow the user to
                  customize some features of the general options of the website
                  (remember the language, the top level of portal chosen if the
                  user is a private individual, company or self-employed, etc.
                  and remember it in the next view).
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography>
                <Trans>
                  Analytics cookies: these cookies collect information in an
                  anonymous form. If you consent their installation, Google
                  Analytics cookies will be setted in your device for improving
                  ALLFUNDS website by collecting and reporting information on
                  how you use it.
                </Trans>
              </Typography>
            </li>
          </ul>
          <H2>
            <Trans>What are cookies used for?</Trans>
          </H2>
          <Typography>
            <Trans>
              ALLFUNDS uses cookies to make ALLFUNDS website work properly, help
              us understand our website traffic, and optimize user’s experience.
              Cookies also allow identifying the browser and device through
              which the user is accessing to the website. Accordingly, cookies
              are used in order to facilitate the next visit to the website and
              make it more useful.
            </Trans>
          </Typography>
          <H2>
            <Trans>How do we use them?</Trans>
          </H2>
          <Typography>
            <Trans>
              The cookies help the user to move around and use the website.
              Essential security features and functionalities may be affected if
              they were not installed, but in any case, it will be the user’s
              decision to enable them or not.
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              Namely, ALLFUNDS website uses the following type of cookies:
            </Trans>
          </Typography>
          <Typography>
            <Trans>COOKIES INSERTED IN THE WEBSITE</Trans>
          </Typography>

          <Table>
            <Table>
              <TableHead>
                <TableRow>
                  {headers().map((header) => (
                    <TableCell>
                      <Typography variant="h5">{header}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map(({
                  a, b, c, d, e,
                }) => (
                  <TableRow>
                    <TableCell>
                      <Typography pl={1} variant="body" color="text.primary">
                        {a}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body" color="text.primary">
                        {b}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body" color="text.primary">
                        {c}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body" color="text.primary">
                        {d}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body" color="text.primary">
                        {e}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Table>

          <H2>
            <Trans>How to disable cookies?</Trans>
          </H2>
          <Typography>
            <Trans>
              The user can change his / her cookies preferences at any time
              through the user settings panel
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              Additionally, every browser allows users to disable cookies.
              Indeed, most browsers offer the possibility of managing cookies in
              order to obtain a more precise control over privacy. These
              settings are located in the “Options” or “Preferences” tabs in the
              browser menu. In order to disable cookies in each browser, users
              may follow the instructions below:
            </Trans>
          </Typography>
          <Button
            sx={{ marginY: "1rem" }}
            variant="contained"
            color="secondary"
            onClick={() => setShowModal(true)}
          >
            <Typography color="white">
              <Trans>Configure Cookies</Trans>
            </Typography>
          </Button>
          <ul>
            <li>
              <Button
                onClick={() => window.open(
                  "https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=es",
                  "_blank",
                )}
              >
                <Typography>
                  <Trans>Google Chrome</Trans>
                </Typography>
              </Button>
            </li>
            <li>
              <Button
                onClick={() => window.open(
                  "http://support.mozilla.org/es/home#sthash.cEpCdreI.dpuf",
                  "_blank",
                )}
              >
                <Typography>
                  <Trans>Firefox</Trans>
                </Typography>
              </Button>
            </li>
            <li>
              <Button
                onClick={() => window.open(
                  "http://support.microsoft.com/ph/807/es-es",
                  "_blank",
                )}
              >
                <Typography>
                  <Trans> Internet Explorer</Trans>
                </Typography>
              </Button>
            </li>
            <li>
              <Button
                onClick={() => window.open(
                  "http://support.apple.com/kb/HT1677?viewlocale=es_ES",
                  "_blank",
                )}
              >
                <Typography>
                  <Trans>Safari</Trans>
                </Typography>
              </Button>
            </li>
            <li>
              <Button
                onClick={() => window.open(
                  "http://help.opera.com/Windows/8.54/es-ES/index.html",
                  "_blank",
                )}
              >
                <Typography>
                  <Trans>Opera</Trans>
                </Typography>
              </Button>
            </li>
          </ul>
          <H2>
            <Trans>Processing of personal data</Trans>
          </H2>
          <Typography>
            <Trans>
              Data Controller: The data controller is ALLFUNDS BANK S.A.U.
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              Data Protection Officer contact:You may also contact our Data
              Protection Officer at the postal address, Calle de los Padres
              Dominicos 7, 28050 - Madrid, Spain, or by email at
              dpo@allfunds.com.
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              Purposes of the processing of personal data: The purposes of the
              processing of personal data are indicated in point 3 of the
              Cookies Policy (“How do we use them?”).
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              ALLFUNDS uses its own technical cookies, because they are
              essential for using all the options and functionalities of the
              website.
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              The legal basis for using the other cookies is your consent, which
              you can manage and revoke through the Cookies Settings Panel by
              clicking here, or as indicated in the section 4 (How to disable
              cookies?).
            </Trans>
          </Typography>
          <H2>
            <Trans>Recipients of personal data</Trans>
          </H2>
          <Typography>
            <Trans>
              ALLFUNDS may share your personal data with the entities indicated
              below:
            </Trans>
          </Typography>
          <ul>
            <li>
              <Typography>
                <Trans>
                  The data may be communicated to public authorities and bodies,
                  for the fulfilment of legal obligations.
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography>
                <Trans>
                  Your personal data may be processed by providers, that will
                  act on our behalf (as data processors) as a consequence of
                  their provision of services.
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography>
                <Trans>
                  Regarding third party cookies, we remind you that these
                  cookies (i) may be sent from a domain that it is not managed
                  by ALLFUNDS or (ii) may be sent from our domain, but the
                  information collected is managed by the third party.
                </Trans>
              </Typography>
            </li>
          </ul>
          <H2>
            <Trans>International transfers of personal data</Trans>
          </H2>
          <Typography>
            <Trans>
              The personal data is generally processed in the European Economic
              Area (hereinafter, the “EEA”) or in countries that guarantee an
              adequate level of protection of your personal data.
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              In certain situations, we may transfer your personal data outside
              of EEA. In such cases, we guarantee the security and legitimacy of
              data processing by requiring our suppliers to meet the applicable
              guarantees according to data protection legislation (binding
              corporate rules, Privacy Shield -in case the provider is located
              in USA -, or standard data protection clauses adopted by the
              European Commission).
            </Trans>
          </Typography>
          <H2>
            <Trans>Data retention period</Trans>
          </H2>
          <Typography>
            <Trans>
              The data will be process during the periods indicated in the
              section 3 (How do we use them?) and provide that the consents
              remain in force.
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              According to the data protection regulations, ALLFUNDS will keep
              your personal data as long as the company needs it for the
              purposes for which it was collected, taking into account local
              laws and contractual obligations. When we will no longer need such
              personal data, we will duly block it during applicable statutes of
              limitation and/or retention periods set forth by law and we will
              only be able to process it in order to:
            </Trans>
          </Typography>
          <ul>
            <li>
              <Trans>
                Comply with the legal obligations that may be applicable;
              </Trans>
            </li>
            <li>
              <Trans>
                Make the personal data available to the competent Public
                Administrations, Courts and Tribunals or the Public Prosecutor's
                Office.
              </Trans>
            </li>
          </ul>
          <Typography>
            <Trans>
              When such retention periods end, we will physically destroy your
              personal data.
            </Trans>
          </Typography>
          <H2>
            <Trans>
              Personal data rights and complaints before the Supervisory
              Authority
            </Trans>
          </H2>
          <Typography>
            <Trans>
              You can exercise your personal data rights (access, rectification
              and erasure, restriction of processing, data portability) by
              sending a communication to ALLFUNDS’s privacy team, providing a
              copy of a personal identification document:
            </Trans>
          </Typography>
          <ul>
            <li>
              <Trans>
                ALLFUNDS’s postal address: Calle de los Padres Dominicos 7,
                28050 - Madrid, Spain.
              </Trans>
            </li>
            <li>
              <Trans>ALLFUNDS’s e-mail: dpo@allfunds.com</Trans>
            </li>
          </ul>
          <Typography>
            <Trans>
              You may also contact our Data Protection Officer at the postal
              address indicated above or by email at dpo@allfunds.com. In any
              case, the data subject may always contact with the competent
              authority regarding data protection in accordance with the
              provisions of the applicable legislation.
            </Trans>
          </Typography>
          <H2>
            <Trans>Additional remarks</Trans>
          </H2>
          <Typography>
            <Trans>
              Web browsers are the platforms in charge of storing cookies and
              from these browsers you must exercise your right to delete or
              deactivate them.
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              ALLFUNDS is not responsible if the deactivation of the cookies
              prevents or hinders the proper functioning of the website, nor can
              it guarantee the correct or incorrect handling of the cookies by
              the mentioned browsers.
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              In some cases, it is required to install cookies so the browser
              does not ignore you previous decision to not accept them.
            </Trans>
          </Typography>
          <H2>
            <Trans>Update of Cookies Policy</Trans>
          </H2>
          <Typography>
            <Trans>
              This Cookies Policy may change depending on which cookies are used
              by ALLFUNDS or how we use them.
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              ALLFUNDS recommends to review this document each time the user
              accesses the website in order to be properly informed about how
              and for what purpose we use cookies and to be aware of any change
              that may be introduced.
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              This Cookies Policy has been drafted as short and clear as
              possible, but if you would need additional information, you can
              get in contact with ALLFUNDS at any time. For additional
              information, related with the processing of your personal data,
              please check out our &nbsp;
              <t />
              <StyledLocalizedLink to="/privacy-policy">
                Privacy policy
              </StyledLocalizedLink>
            </Trans>
          </Typography>
        </Container>
      </NewLayout>
      {showModal && (
        <ModalCookies open onClose={() => setShowModal(false)} />
      )}
    </>
  );
}

const H2 = styled.h2`
  font-size: 24px;
  font-weight: 700;
`;

const StyledLocalizedLink = styled(LocalizedLink)(({ theme }) => `
  color: ${theme.palette.secondary.main};
  text-decoration: underline;
`);

export default CookiesPage;
